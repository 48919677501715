<template>
    <div class="row">
        <div @click="$modal.show('modal-cowrie')" class="insurance-count">
            <i class="flaticon2-edit"></i>
        </div>
        <div class="col-lg-12 p-0">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row group-container pt-4 group-total">
                            <h5 class="title-group">รายละเอียดกรมธรรม์</h5>
                            <div class="col-lg-7">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="text-insurance-title bmd-label-floating">แบบประกัน</label>
                                        <h1
                                            @click="handle.pdf(get(data, 'insurance.main'))"
                                            class="text-insurance-main mt-0 text-danger"
                                        >
                                            {{ get(data, 'insurance.main.text') }}
                                            <i class="flaticon2-magnifier-tool"></i>
                                        </h1>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="text-insurance-title bmd-label-floating">ทุนประกัน</label>
                                        <h1 class="text-danger">{{ nf(get(data, 'form.price')) }}</h1>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-5">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="text-insurance-title bmd-label-floating">อายุ / เพศ</label>
                                        <h2 class="mt-1 font-weight-bold">
                                            {{ get(data, 'form.age') }} ปี |
                                            {{ get(data, 'form.gender') == '0' ? 'ชาย' : 'หญิง' }}
                                        </h2>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="text-insurance-title bmd-label-floating">เบี้ยรายปี</label>
                                        <h2 class="mt-0 text-danger">
                                            <span v-if="data.form.main == 'legacy'">
                                                {{ data.form.legacy == 0 ? '(10 ปี) ' : '(15 ปี) ' }}
                                            </span>
                                            {{ nf(get(data, 'count.main.price')) }}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12"><hr /></div>

                            <div v-if="get(data, 'insurance.main.image')" class="image-insurance-main col-lg-12 mb-3">
                                <img :src="'/media/image/insurance/' + get(data, 'insurance.main.image')" alt="" />
                            </div>

                            <template v-for="(total, key) in get(data, 'count.main')">
                                <div v-if="true" :key="key" :class="total.size == 'large' ? 'col-lg-12' : 'col-lg-6'">
                                    <div class="form-group">
                                        <label class="text-insurance-title bmd-label-floating">{{ total.text }}</label>

                                        <template v-if="total.size == 'large'">
                                            <h2 class="my-1">{{ nf(total.price) }}</h2>
                                            <hr />
                                        </template>
                                        <h3 v-else class="mt-0 text-info">{{ nf(total.price, '00') }}</h3>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div v-if="!isEmpty(get(data, 'count.extra'))" class="col-lg-12">
                        <div class="row group-container mt-5 mb-2 pt-4 group-total">
                            <h5 class="title-group">รายละเอียดสัญญาเพิ่มเติม</h5>
                            <template v-for="extra in get(data, 'count.extra')">
                                <div :key="extra.head.key" class="container-fluid group-total-more">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label class="text-insurance-title bmd-label-floating">
                                                    สัญญาเพิ่มเติม
                                                </label>
                                                <h1
                                                    @click="handle.pdf(get(extra, 'pdf'))"
                                                    class="text-danger text-insurance-extra mt-0"
                                                >
                                                    {{ extra.head.text }}
                                                    <i class="flaticon2-magnifier-tool"></i>
                                                </h1>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label class="text-insurance-title bmd-label-floating">PLAN</label>
                                                <h2 class="mt-0 text-info font-weight-bold">{{ extra.head.plan }}</h2>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label class="text-insurance-title bmd-label-floating">
                                                    เบี้ยรายปี
                                                </label>
                                                <h2 class="mt-0 text-danger">
                                                    {{ extra.price[0] != 0 ? nf(extra.price[0]) : 'ไม่เข้าเงื่อนไข' }}
                                                </h2>
                                            </div>
                                        </div>
                                        <div v-if="false" class="col-lg-6">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="text-insurance-title bmd-label-floating">
                                                        เบี้ยรายปี
                                                    </label>
                                                    <h2 class="mt-0 text-danger">
                                                        {{
                                                            extra.price[0] != 0 ? nf(extra.price[0]) : 'ไม่เข้าเงื่อนไข'
                                                        }}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div v-if="false" class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="bmd-label-floating">เบี้ยรายเดือน</label>
                                                    <h3 class="mt-0 text-danger">
                                                        {{
                                                            extra.price[1] != 0 ? nf(extra.price[1]) : 'ไม่เข้าเงื่อนไข'
                                                        }}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12"><hr /></div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-5 mb-3">
                        <div class="row group-container pt-4 pb-4 group-sumary">
                            <h5 class="title-group">สรุปการจ่ายเบี้ย</h5>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="text-insurance-title mt-1 bmd-label-floating">
                                        เบี้ยประกันรายปี
                                    </label>
                                    <h1 class="mt-2 text-danger">{{ nf(data.total) }}</h1>
                                </div>
                                <hr />
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="text-insurance-title mt-3 bmd-label-floating">
                                        เบี้ยประกัน 6 เดือน
                                    </label>
                                    <h1 class="mt-2 text-danger">{{ nf(data.total * 0.51) }}</h1>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="text-insurance-title mt-3 bmd-label-floating">
                                        เบี้ยประกัน 3 เดือน
                                    </label>
                                    <h1 class="mt-2 text-danger">{{ nf(data.total * 0.27) }}</h1>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="text-insurance-title mt-3 bmd-label-floating">
                                        เบี้ยประกันรายเดือน
                                    </label>
                                    <h1 class="mt-2 text-danger">{{ nf(data.total * 0.09) }}</h1>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="text-insurance-title mt-3 bmd-label-floating">
                                        เบี้ยประกันรายวัน
                                    </label>
                                    <h1 class="mt-2 text-danger">{{ nf(data.total / 365) }}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal name="modal-pdf-insurance" width="98%" title="รายละเอียดกรมธรรม์">
            <div class="modal-body">
                <div class="col-lg-12 pt-1">
                    <pdf :page="data.pdf.page" :source="data.pdf.src" />
                </div>
            </div>
        </modal>

        <modal name="modal-cowrie" width="98%" title="แบบฟอร์มกรมธรรม์">
            <div class="modal-body">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">กรมธรรม์หลัก</label>
                                <select v-model="data.form.main" class="form-control">
                                    <template v-for="(main, key) in get(store, 'insurance.main')">
                                        <option :key="key" :value="key">{{ main.text }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>

                        <!-- //todo 10/15pay -->
                        <div v-if="data.form.main == 'pay15'" class="col-lg-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">ชำระ</label>
                                <select v-model="data.form.pay15" class="form-control">
                                    <option :value="0">10 ปี</option>
                                    <option :value="1">15 ปี</option>
                                </select>
                            </div>
                        </div>

                        <!-- //todo pay life plus -->
                        <div v-if="data.form.main == 'paylifeplus'" class="col-lg-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">ชำระ</label>
                                <select v-model="data.form.paylifeplus" class="form-control">
                                    <option :value="0">10 ปี</option>
                                    <option :value="1">15 ปี</option>
                                    <option :value="2">20 ปี</option>
                                </select>
                            </div>
                        </div>

                        <!-- //todo ci supercare -->
                        <div v-if="data.form.main == 'ci10' || data.form.main == 'ci20'" class="col-lg-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">โรคร้ายแรง</label>
                                <select v-model="data.form.supercare" class="form-control">
                                    <option :value="0">รวมโรคร้ายแรง</option>
                                    <option :value="1">ไม่รวมโรคร้ายแรง</option>
                                </select>
                            </div>
                        </div>

                        <!-- //todo ci ciprocare -->
                        <div v-if="data.form.main == 'ciprocare'" class="col-lg-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">รวมโรคร้ายแรง</label>
                                <select v-model="data.form.ciprocare" class="form-control">
                                    <option :value="0">รวมประกันชีวิตและโรคร้ายแรง</option>
                                    <option :value="1">เฉพาะประกันชีวิต</option>
                                    <option :value="2">เฉพาะโรคร้ายแรง</option>
                                </select>
                            </div>
                        </div>

                        <!-- //todo legacy -->
                        <div v-if="data.form.main == 'legacy'" class="col-lg-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">แบบชำระเบี้ยประกันภัย</label>
                                <select v-model="data.form.legacy" class="form-control">
                                    <option :value="0">ชำระ 10 ปี</option>
                                    <option :value="1">ชำระ 15 ปี</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">ทุนประกัน</label>
                                <select v-model="data.form.price" class="form-control">
                                    <template v-for="(price, key) in get(store, 'insurance.price')">
                                        <option :key="key" :value="price">{{ nf(price) }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5">
                            <div class="form-group">
                                <label class="bmd-label-floating">อายุ</label>
                                <select v-model="data.form.age" class="form-control">
                                    <template v-for="(insurance, key) in get(data, 'insurance.main.rate')">
                                        <option :key="key" :value="key">{{ nf(key) }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7">
                            <div class="form-group">
                                <label class="bmd-label-floating">เพศ</label>
                                <select v-model="data.form.gender" class="form-control">
                                    <option :value="0">เพศชาย</option>
                                    <option :value="1">เพศหญิง</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-12" style="display: none">
                            <div class="form-group">
                                <label class="bmd-label-floating">ขั้นอาชีพ</label>
                                <select v-model="data.form.career" class="form-control">
                                    <option :value="0">ขั้นอาชีพที่ 1 - 2</option>
                                    <option :value="1">ขั้นอาชีพที่ 3</option>
                                    <option :value="2">ขั้นอาชีพที่ 4</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row extra-insurance mt-4">
                        <template v-for="(extra, key) in get(data, 'form.extra')">
                            <div :key="key" class="col-lg-12">
                                <label class="title-extra-insurance mt-3 mb-4">{{ extra.text }}</label>
                                <template v-for="(pack, packKey) in get(extra, 'packages')">
                                    <div :key="packKey" class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="custom-control custom-checkbox">
                                                <input
                                                    v-model="data.form.extra[key].packages[packKey].checked"
                                                    :id="'extra-checked-' + key + packKey"
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                />
                                                <label
                                                    :for="'extra-checked-' + key + packKey"
                                                    class="custom-control-label"
                                                >
                                                    {{ pack.text }}
                                                </label>
                                            </div>
                                        </div>
                                        <template v-if="isArray(data.form.extra[key].packages[packKey].plan)">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="row">
                                                    <template
                                                        v-for="(planArr, planArrKey) in data.form.extra[key].packages[
                                                            packKey
                                                        ].plan"
                                                    >
                                                        <div :key="planArrKey" class="col-lg-6 col-md-6 my-2">
                                                            <div class="form-group">
                                                                <select
                                                                    v-model="
                                                                        data.form.extra[key].packages[packKey].plan[
                                                                            planArrKey
                                                                        ].selected
                                                                    "
                                                                    :readonly="
                                                                        data.form.extra[key].packages[packKey].checked
                                                                            ? false
                                                                            : true
                                                                    "
                                                                    class="form-control extra-plan-select"
                                                                >
                                                                    <template v-for="(plan, planKey) in planArr.option">
                                                                        <option :key="planKey" :value="planKey">
                                                                            {{ plan }}
                                                                        </option>
                                                                    </template>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-lg-6 col-md-6 my-2">
                                                <div class="form-group">
                                                    <select
                                                        v-model="data.form.extra[key].packages[packKey].plan.selected"
                                                        :readonly="
                                                            data.form.extra[key].packages[packKey].checked
                                                                ? false
                                                                : true
                                                        "
                                                        class="form-control extra-plan-select"
                                                    >
                                                        <template v-for="(plan, planKey) in pack.plan.option">
                                                            <option :key="planKey" :value="planKey">{{ plan }}</option>
                                                        </template>
                                                    </select>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from '@vue/composition-api';

//* plugins
import { state, nf } from '@/assets/js/tooltify';
import { cloneDeep, each, get, set, size, isArray, isEmpty } from 'lodash';

//* components
import modal from '@/components/modules/modal.module.vue';
import pdf from '@/components/modules/pdf.module.vue';

export default {
    name: 'cowrie',
    components: { modal, pdf },
    setup(props, Vue) {
        const store = reactive({
            insurance: state.getters('INSURANCE_GETTERS'),
        });

        const data = reactive({
            form: {
                main: 'pay20',
                gender: 0,
                price: 150000,
                age: 20,
                career: 0,

                pay15: 0,
                paylifeplus: 0,
                supercare: 0,
                ciprocare: 0,
                legacy: 0,
                extra: cloneDeep(store.insurance?.extra),
            },
            count: {
                main: [],
                extra: [],
            },
            insurance: {
                main: {},
            },
            total: 0,
            pdf: {
                src: '',
                page: 1,
            },
        });

        onMounted(() => {
            set(data, 'insurance.main', get(store, 'insurance.main.' + data.form.main));
            handle.count();
        });

        watch(
            () => data.form.main,
            (main) => {
                let mainSelected = get(store, 'insurance.main.' + main);
                set(data, 'insurance.main', mainSelected);

                if (size(mainSelected.rate) < data.form.age) {
                    set(data, 'form.age', 1);
                }
            },
        );

        watch(data.form, () => {
            handle.count();
        });

        const handle = {
            count: () => {
                //! insurance main
                let insuranceMain = get(data, 'insurance.main.rate');

                let selected = get(data, 'form.main');
                let gender = get(data, 'form.gender');
                let price = get(data, 'form.price');
                let age = get(data, 'form.age');
                let career = get(data, 'form.career');

                //* 20 Pay
                if (selected == 'pay20') {
                    let rate = insuranceMain[age][gender];
                    let insurancePrice = (rate / 1000) * price;

                    set(data, 'total', insurancePrice);
                    set(data, 'count.main.price', insurancePrice);
                }

                //* Pay Life Plus
                if (selected == 'paylifeplus') {
                    let paylifeplus = get(data, 'form.paylifeplus');
                    let rate = insuranceMain[age][paylifeplus][gender];
                    let insurancePrice = (rate / 1000) * price;

                    set(data, 'total', insurancePrice);
                    set(data, 'count.main.price', insurancePrice);
                }

                //* 15/25 Endowment
                if (selected == 'ae15') {
                    let rate = insuranceMain[age][gender];
                    let insurancePrice = (rate / 1000) * price;

                    set(data, 'total', insurancePrice);
                    set(data, 'count.main.price', insurancePrice);
                }

                //* 10 & 15Pay
                if (selected == 'pay15') {
                    let pay15 = get(data, 'form.pay15');
                    let rate = insuranceMain[age][pay15][gender];
                    let insurancePrice = (rate / 1000) * price;

                    set(data, 'total', insurancePrice);
                    set(data, 'count.main.price', insurancePrice);
                }

                //* supercare
                if (selected == 'ci10' || selected == 'ci20') {
                    let supercare = get(data, 'form.supercare');
                    let rate = insuranceMain[age][supercare][gender];
                    let insurancePrice = (rate / 1000) * price;

                    set(data, 'total', insurancePrice);
                    set(data, 'count.main.price', insurancePrice);
                }

                //* procare
                if (selected == 'ciprocare') {
                    let ciprocare = get(data, 'form.ciprocare');
                    let rate = insuranceMain[age][gender][ciprocare];
                    let insurancePrice = (rate / 1000) * price;

                    set(data, 'total', insurancePrice);
                    set(data, 'count.main.price', insurancePrice);
                }

                //* legacy
                if (selected == 'legacy') {
                    let legacy = get(data, 'form.legacy');
                    let rate = insuranceMain[age][legacy][gender];
                    let insurancePrice = (rate / 1000) * price;

                    set(data, 'total', insurancePrice);
                    set(data, 'count.main.price', insurancePrice);
                }

                //! insurance extra
                set(data, 'count.extra', []);
                let insuranceCareer = ['ขั้นอาชีพที่ 1 - 2', 'ขั้นอาชีพที่ 3', 'ขั้นอาชีพที่ 4'];

                const extraCount = (extra, key) => {
                    //* health happy & h&s & h&s extra
                    if (key == 'hh' || key == 'hs' || key == 'hsx') {
                        let extraPrice = extra.rate[age][extra.plan.selected][gender];

                        data.total += extraPrice;
                        data.count.extra.push({
                            head: {
                                key: key,
                                text: extra.text,
                                career: insuranceCareer[career],
                                page: extra.page,
                                plan: extra.plan.option[extra.plan.selected],
                            },
                            pdf: extra,
                            image: extra.image,
                            price: [extraPrice, extraPrice * 0.09],
                        });
                    }

                    //* hb
                    if (key == 'hb') {
                        let extraPrice = (extra.plan.selected * extra.rate[age][career]) / 100;

                        data.total += extraPrice;
                        data.count.extra.push({
                            head: {
                                key: key,
                                text: extra.text,
                                career: insuranceCareer[career],
                                page: extra.page,
                                plan: extra.plan.option[extra.plan.selected],
                            },
                            pdf: extra,
                            image: extra.image,
                            price: [extraPrice, extraPrice * 0.09],
                        });
                    }

                    //* hb extra
                    if (key == 'hbx') {
                        let extraPrice = (extra.plan.selected * extra.rate[age][gender]) / 100;

                        data.total += extraPrice;
                        data.count.extra.push({
                            head: {
                                key: key,
                                text: extra.text,
                                career: insuranceCareer[career],
                                page: extra.page,
                                plan: extra.plan.option[extra.plan.selected],
                            },
                            pdf: extra,
                            image: extra.image,
                            price: [extraPrice, extraPrice * 0.09],
                        });
                    }

                    //* AIA Health Cancer
                    if (key == 'ahc') {
                        let extraPrice =
                            (extra.rate[age][extra.plan[1].selected][gender] * extra.plan[0].selected) / 10;

                        data.total += extraPrice;
                        data.count.extra.push({
                            head: {
                                key: key,
                                text: extra.text,
                                career: insuranceCareer[career],
                                page: extra.page,
                                plan: extra.plan[0].option[extra.plan[0].selected],
                            },
                            pdf: extra,
                            image: extra.image,
                            price: [extraPrice, extraPrice * 0.09],
                        });
                    }

                    //* AIA CI Plus & AIA CI Care & AIA CI Topup && AIA Multi Pay CI
                    if (key === 'cr') {
                        let rate = extra.rate[age][gender];
                        let extraPrice = rate * extra.plan.selected;

                        data.total += extraPrice;

                        data.count.extra.push({
                            head: {
                                key: key,
                                text: extra.text,
                                career: insuranceCareer[career],
                                page: extra.page,
                                plan: extra.plan.option[extra.plan.selected],
                            },
                            pdf: extra,
                            image: extra.image,
                            price: [extraPrice, extraPrice * 0.09],
                        });
                    }

                    //* AIA CI Plus & AIA CI Care & AIA CI Topup && AIA Multi Pay CI
                    if (key === 'cip' || key === 'cic' || key === 'cit' || key === 'mpci') {
                        let rate = extra.rate[age][gender];
                        let extraPrice = (rate / 1000) * extra.plan.selected;

                        data.total += extraPrice;

                        data.count.extra.push({
                            head: {
                                key: key,
                                text: extra.text,
                                career: insuranceCareer[career],
                                page: extra.page,
                                plan: extra.plan.option[extra.plan.selected],
                            },
                            pdf: extra,
                            image: extra.image,
                            price: [extraPrice, extraPrice * 0.09],
                        });
                    }

                    //* AIA AI RCC & AIA ADD RCC
                    if (key == 'ai' || key == 'add') {
                        let rate = extra.rate[career];
                        let extraPrice = (rate / 1000) * extra.plan.selected;

                        data.total += extraPrice;

                        data.count.extra.push({
                            head: {
                                key: key,
                                text: extra.text,
                                career: insuranceCareer[career],
                                page: extra.page,
                                plan: extra.plan.option[extra.plan.selected],
                            },
                            pdf: extra,
                            image: extra.image,
                            price: [extraPrice, extraPrice * 0.09],
                        });
                    }
                };

                each(get(data, 'form.extra'), (extra) => {
                    each(get(extra, 'packages'), (pack, key) => {
                        if (pack.checked) {
                            extraCount(pack, key);
                        }
                    });
                });
            },
            pdf: (insurance) => {
                set(data, 'pdf', {
                    src: insurance.pdf,
                    page: insurance.page,
                });

                Vue.root.$modal.show('modal-pdf-insurance');
            },
        };

        return { store, data, handle, get, set, nf, isArray, isEmpty };
    },
};
</script>

<style lang="scss" scoped>
.insurance-count {
    position: fixed;
    right: 25px;
    bottom: 25px;
    background: #d62454;
    color: white;
    padding: 3px 14px;
    border-radius: 20px;
    font-size: 40px;
    z-index: 1;
}
.extra-plan-select {
    margin-top: -8px;
}
.text-insurance-main {
    width: max-content;
    i {
        font-size: 38px;
        position: relative;
        top: -4px;
    }
}
.image-insurance-main {
    img {
        width: 100%;
        border-radius: 15px;
        border: solid 1px #e4e5e4;
    }
}
.text-insurance-extra {
    width: max-content;
    i {
        font-size: 38px;
        position: relative;
        top: -4px;
    }
}
.text-insurance-title {
    font-size: 28px !important;
    font-weight: bold;
}
.title-extra-insurance {
    font-size: 25px;
}

.group-total {
    .group-total-more {
        .col-md-12 {
            margin-bottom: -10px;
        }
    }
}

.form-control {
    background-image: linear-gradient(0deg, #e2e2e2 2px, rgba(156, 39, 176, 0) 0),
        linear-gradient(0deg, #d2d2d2 1px, rgba(210, 210, 210, 0) 0);
    border: none;
    border-radius: 0;
    padding: 0.375rem 0rem;
    font-weight: bold;

    &:disabled,
    &[readonly] {
        opacity: 0.2;
        background-color: #ff000000;
    }
}

.form-group {
    h1,
    h2,
    h3,
    h5 {
        margin-bottom: -5px;
        font-weight: 700;
        line-height: 1;
    }
    label {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .main-panel {
        .content {
            .group-main {
                margin-right: 10px;
            }
            .group-more {
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 767px) {
    .main-panel {
        .content {
            .group-more {
                .form-group {
                    padding-bottom: 0;
                    margin-top: -5px;
                    margin-bottom: 25px;
                }
            }

            .card {
                .card-header {
                    .header-credit-at {
                        display: none;
                    }
                    .header-btn-login {
                        top: auto;
                        right: 22px;
                        bottom: 12px;
                    }
                }
                .card-footer {
                    margin: 0;
                    .container-fluid {
                        padding: 0;
                        margin: 0;

                        .group-container {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 992px) {
    .main-panel {
        .content {
            .group-total {
                margin-top: 30px;
            }
        }
    }
}
@media (max-width: 1366px) {
    .main-panel {
        .content {
            .extra-insurance {
                .form-group {
                    label {
                        font-size: 22px;
                    }
                    .form-control {
                        font-size: 25px;
                        color: #34495e;
                    }
                }
            }
            .form-group {
                label {
                    font-size: 25px;
                }
                .form-control {
                    font-size: 35px;
                    color: #d62454;
                }
            }
            .group-more {
                .title-group-more {
                    font-size: 22px;
                    top: -17px !important;
                }
            }
            .group-container {
                .title-group {
                    font-size: 22px;
                    top: -17px !important;
                }
            }
        }
    }
}
</style>
