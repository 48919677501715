var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        {
          staticClass: "insurance-count",
          on: {
            click: function ($event) {
              return _vm.$modal.show("modal-cowrie")
            },
          },
        },
        [_c("i", { staticClass: "flaticon2-edit" })]
      ),
      _c("div", { staticClass: "col-lg-12 p-0" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c(
                "div",
                { staticClass: "row group-container pt-4 group-total" },
                [
                  _c("h5", { staticClass: "title-group" }, [
                    _vm._v("รายละเอียดกรมธรรม์"),
                  ]),
                  _c("div", { staticClass: "col-lg-7" }, [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "text-insurance-title bmd-label-floating",
                          },
                          [_vm._v("แบบประกัน")]
                        ),
                        _c(
                          "h1",
                          {
                            staticClass: "text-insurance-main mt-0 text-danger",
                            on: {
                              click: function ($event) {
                                _vm.handle.pdf(
                                  _vm.get(_vm.data, "insurance.main")
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  _vm.get(_vm.data, "insurance.main.text")
                                ) +
                                "\n                                        "
                            ),
                            _c("i", {
                              staticClass: "flaticon2-magnifier-tool",
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "text-insurance-title bmd-label-floating",
                          },
                          [_vm._v("ทุนประกัน")]
                        ),
                        _c("h1", { staticClass: "text-danger" }, [
                          _vm._v(
                            _vm._s(_vm.nf(_vm.get(_vm.data, "form.price")))
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-lg-5" }, [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "text-insurance-title bmd-label-floating",
                          },
                          [_vm._v("อายุ / เพศ")]
                        ),
                        _c("h2", { staticClass: "mt-1 font-weight-bold" }, [
                          _vm._v(
                            "\n                                        " +
                              _vm._s(_vm.get(_vm.data, "form.age")) +
                              " ปี |\n                                        " +
                              _vm._s(
                                _vm.get(_vm.data, "form.gender") == "0"
                                  ? "ชาย"
                                  : "หญิง"
                              ) +
                              "\n                                    "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "text-insurance-title bmd-label-floating",
                          },
                          [_vm._v("เบี้ยรายปี")]
                        ),
                        _c("h2", { staticClass: "mt-0 text-danger" }, [
                          _vm.data.form.main == "legacy"
                            ? _c("span", [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.data.form.legacy == 0
                                        ? "(10 ปี) "
                                        : "(15 ปี) "
                                    ) +
                                    "\n                                        "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n                                        " +
                              _vm._s(
                                _vm.nf(_vm.get(_vm.data, "count.main.price"))
                              ) +
                              "\n                                    "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._m(0),
                  _vm.get(_vm.data, "insurance.main.image")
                    ? _c(
                        "div",
                        { staticClass: "image-insurance-main col-lg-12 mb-3" },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "/media/image/insurance/" +
                                _vm.get(_vm.data, "insurance.main.image"),
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(
                    _vm.get(_vm.data, "count.main"),
                    function (total, key) {
                      return [
                        true
                          ? _c(
                              "div",
                              {
                                key: key,
                                class:
                                  total.size == "large"
                                    ? "col-lg-12"
                                    : "col-lg-6",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-insurance-title bmd-label-floating",
                                      },
                                      [_vm._v(_vm._s(total.text))]
                                    ),
                                    total.size == "large"
                                      ? [
                                          _c("h2", { staticClass: "my-1" }, [
                                            _vm._v(_vm._s(_vm.nf(total.price))),
                                          ]),
                                          _c("hr"),
                                        ]
                                      : _c(
                                          "h3",
                                          { staticClass: "mt-0 text-info" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.nf(total.price, "00"))
                                            ),
                                          ]
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ]),
            !_vm.isEmpty(_vm.get(_vm.data, "count.extra"))
              ? _c("div", { staticClass: "col-lg-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "row group-container mt-5 mb-2 pt-4 group-total",
                    },
                    [
                      _c("h5", { staticClass: "title-group" }, [
                        _vm._v("รายละเอียดสัญญาเพิ่มเติม"),
                      ]),
                      _vm._l(
                        _vm.get(_vm.data, "count.extra"),
                        function (extra) {
                          return [
                            _c(
                              "div",
                              {
                                key: extra.head.key,
                                staticClass: "container-fluid group-total-more",
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-lg-12" }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "text-insurance-title bmd-label-floating",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                สัญญาเพิ่มเติม\n                                            "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "h1",
                                        {
                                          staticClass:
                                            "text-danger text-insurance-extra mt-0",
                                          on: {
                                            click: function ($event) {
                                              _vm.handle.pdf(
                                                _vm.get(extra, "pdf")
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(extra.head.text) +
                                              "\n                                                "
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "flaticon2-magnifier-tool",
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "col-lg-6" }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "text-insurance-title bmd-label-floating",
                                        },
                                        [_vm._v("PLAN")]
                                      ),
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "mt-0 text-info font-weight-bold",
                                        },
                                        [_vm._v(_vm._s(extra.head.plan))]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "col-lg-6" }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "text-insurance-title bmd-label-floating",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                เบี้ยรายปี\n                                            "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "h2",
                                        { staticClass: "mt-0 text-danger" },
                                        [
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(
                                                extra.price[0] != 0
                                                  ? _vm.nf(extra.price[0])
                                                  : "ไม่เข้าเงื่อนไข"
                                              ) +
                                              "\n                                            "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  false
                                    ? _c("div", { staticClass: "col-lg-6" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-lg-12" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "text-insurance-title bmd-label-floating",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                    เบี้ยรายปี\n                                                "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "h2",
                                                  {
                                                    staticClass:
                                                      "mt-0 text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                    " +
                                                        _vm._s(
                                                          extra.price[0] != 0
                                                            ? _vm.nf(
                                                                extra.price[0]
                                                              )
                                                            : "ไม่เข้าเงื่อนไข"
                                                        ) +
                                                        "\n                                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        false
                                          ? _c(
                                              "div",
                                              { staticClass: "col-lg-12" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "bmd-label-floating",
                                                      },
                                                      [_vm._v("เบี้ยรายเดือน")]
                                                    ),
                                                    _c(
                                                      "h3",
                                                      {
                                                        staticClass:
                                                          "mt-0 text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              extra.price[1] !=
                                                                0
                                                                ? _vm.nf(
                                                                    extra
                                                                      .price[1]
                                                                  )
                                                                : "ไม่เข้าเงื่อนไข"
                                                            ) +
                                                            "\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm._m(1, true),
                                ]),
                              ]
                            ),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "col-lg-12 mt-5 mb-3" }, [
              _c(
                "div",
                { staticClass: "row group-container pt-4 pb-4 group-sumary" },
                [
                  _c("h5", { staticClass: "title-group" }, [
                    _vm._v("สรุปการจ่ายเบี้ย"),
                  ]),
                  _c("div", { staticClass: "col-lg-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-insurance-title mt-1 bmd-label-floating",
                        },
                        [
                          _vm._v(
                            "\n                                    เบี้ยประกันรายปี\n                                "
                          ),
                        ]
                      ),
                      _c("h1", { staticClass: "mt-2 text-danger" }, [
                        _vm._v(_vm._s(_vm.nf(_vm.data.total))),
                      ]),
                    ]),
                    _c("hr"),
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-insurance-title mt-3 bmd-label-floating",
                        },
                        [
                          _vm._v(
                            "\n                                    เบี้ยประกัน 6 เดือน\n                                "
                          ),
                        ]
                      ),
                      _c("h1", { staticClass: "mt-2 text-danger" }, [
                        _vm._v(_vm._s(_vm.nf(_vm.data.total * 0.51))),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-insurance-title mt-3 bmd-label-floating",
                        },
                        [
                          _vm._v(
                            "\n                                    เบี้ยประกัน 3 เดือน\n                                "
                          ),
                        ]
                      ),
                      _c("h1", { staticClass: "mt-2 text-danger" }, [
                        _vm._v(_vm._s(_vm.nf(_vm.data.total * 0.27))),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-insurance-title mt-3 bmd-label-floating",
                        },
                        [
                          _vm._v(
                            "\n                                    เบี้ยประกันรายเดือน\n                                "
                          ),
                        ]
                      ),
                      _c("h1", { staticClass: "mt-2 text-danger" }, [
                        _vm._v(_vm._s(_vm.nf(_vm.data.total * 0.09))),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-insurance-title mt-3 bmd-label-floating",
                        },
                        [
                          _vm._v(
                            "\n                                    เบี้ยประกันรายวัน\n                                "
                          ),
                        ]
                      ),
                      _c("h1", { staticClass: "mt-2 text-danger" }, [
                        _vm._v(_vm._s(_vm.nf(_vm.data.total / 365))),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "modal-pdf-insurance",
            width: "98%",
            title: "รายละเอียดกรมธรรม์",
          },
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              { staticClass: "col-lg-12 pt-1" },
              [
                _c("pdf", {
                  attrs: { page: _vm.data.pdf.page, source: _vm.data.pdf.src },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "modal-cowrie",
            width: "98%",
            title: "แบบฟอร์มกรมธรรม์",
          },
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "bmd-label-floating" }, [
                      _vm._v("กรมธรรม์หลัก"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.form.main,
                            expression: "data.form.main",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data.form,
                              "main",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _vm._l(
                          _vm.get(_vm.store, "insurance.main"),
                          function (main, key) {
                            return [
                              _c(
                                "option",
                                { key: key, domProps: { value: key } },
                                [_vm._v(_vm._s(main.text))]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm.data.form.main == "pay15"
                  ? _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "bmd-label-floating" }, [
                          _vm._v("ชำระ"),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.form.pay15,
                                expression: "data.form.pay15",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data.form,
                                  "pay15",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v("10 ปี"),
                            ]),
                            _c("option", { domProps: { value: 1 } }, [
                              _vm._v("15 ปี"),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.data.form.main == "paylifeplus"
                  ? _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "bmd-label-floating" }, [
                          _vm._v("ชำระ"),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.form.paylifeplus,
                                expression: "data.form.paylifeplus",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data.form,
                                  "paylifeplus",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v("10 ปี"),
                            ]),
                            _c("option", { domProps: { value: 1 } }, [
                              _vm._v("15 ปี"),
                            ]),
                            _c("option", { domProps: { value: 2 } }, [
                              _vm._v("20 ปี"),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.data.form.main == "ci10" || _vm.data.form.main == "ci20"
                  ? _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "bmd-label-floating" }, [
                          _vm._v("โรคร้ายแรง"),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.form.supercare,
                                expression: "data.form.supercare",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data.form,
                                  "supercare",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v("รวมโรคร้ายแรง"),
                            ]),
                            _c("option", { domProps: { value: 1 } }, [
                              _vm._v("ไม่รวมโรคร้ายแรง"),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.data.form.main == "ciprocare"
                  ? _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "bmd-label-floating" }, [
                          _vm._v("รวมโรคร้ายแรง"),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.form.ciprocare,
                                expression: "data.form.ciprocare",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data.form,
                                  "ciprocare",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v("รวมประกันชีวิตและโรคร้ายแรง"),
                            ]),
                            _c("option", { domProps: { value: 1 } }, [
                              _vm._v("เฉพาะประกันชีวิต"),
                            ]),
                            _c("option", { domProps: { value: 2 } }, [
                              _vm._v("เฉพาะโรคร้ายแรง"),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.data.form.main == "legacy"
                  ? _c("div", { staticClass: "col-lg-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { staticClass: "bmd-label-floating" }, [
                          _vm._v("แบบชำระเบี้ยประกันภัย"),
                        ]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data.form.legacy,
                                expression: "data.form.legacy",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.data.form,
                                  "legacy",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { domProps: { value: 0 } }, [
                              _vm._v("ชำระ 10 ปี"),
                            ]),
                            _c("option", { domProps: { value: 1 } }, [
                              _vm._v("ชำระ 15 ปี"),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "bmd-label-floating" }, [
                      _vm._v("ทุนประกัน"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.form.price,
                            expression: "data.form.price",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data.form,
                              "price",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _vm._l(
                          _vm.get(_vm.store, "insurance.price"),
                          function (price, key) {
                            return [
                              _c(
                                "option",
                                { key: key, domProps: { value: price } },
                                [_vm._v(_vm._s(_vm.nf(price)))]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-lg-5 col-md-5" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "bmd-label-floating" }, [
                      _vm._v("อายุ"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.form.age,
                            expression: "data.form.age",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data.form,
                              "age",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _vm._l(
                          _vm.get(_vm.data, "insurance.main.rate"),
                          function (insurance, key) {
                            return [
                              _c(
                                "option",
                                { key: key, domProps: { value: key } },
                                [_vm._v(_vm._s(_vm.nf(key)))]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-lg-7 col-md-7" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "bmd-label-floating" }, [
                      _vm._v("เพศ"),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.form.gender,
                            expression: "data.form.gender",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.data.form,
                              "gender",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { domProps: { value: 0 } }, [
                          _vm._v("เพศชาย"),
                        ]),
                        _c("option", { domProps: { value: 1 } }, [
                          _vm._v("เพศหญิง"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-lg-12",
                    staticStyle: { display: "none" },
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "bmd-label-floating" }, [
                        _vm._v("ขั้นอาชีพ"),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.form.career,
                              expression: "data.form.career",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.data.form,
                                "career",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: 0 } }, [
                            _vm._v("ขั้นอาชีพที่ 1 - 2"),
                          ]),
                          _c("option", { domProps: { value: 1 } }, [
                            _vm._v("ขั้นอาชีพที่ 3"),
                          ]),
                          _c("option", { domProps: { value: 2 } }, [
                            _vm._v("ขั้นอาชีพที่ 4"),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "row extra-insurance mt-4" },
                [
                  _vm._l(
                    _vm.get(_vm.data, "form.extra"),
                    function (extra, key) {
                      return [
                        _c(
                          "div",
                          { key: key, staticClass: "col-lg-12" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "title-extra-insurance mt-3 mb-4",
                              },
                              [_vm._v(_vm._s(extra.text))]
                            ),
                            _vm._l(
                              _vm.get(extra, "packages"),
                              function (pack, packKey) {
                                return [
                                  _c(
                                    "div",
                                    { key: packKey, staticClass: "row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-6 col-md-6" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "custom-control custom-checkbox",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.data.form.extra[key]
                                                        .packages[packKey]
                                                        .checked,
                                                    expression:
                                                      "data.form.extra[key].packages[packKey].checked",
                                                  },
                                                ],
                                                staticClass:
                                                  "custom-control-input",
                                                attrs: {
                                                  id:
                                                    "extra-checked-" +
                                                    key +
                                                    packKey,
                                                  type: "checkbox",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.data.form.extra[key]
                                                      .packages[packKey].checked
                                                  )
                                                    ? _vm._i(
                                                        _vm.data.form.extra[key]
                                                          .packages[packKey]
                                                          .checked,
                                                        null
                                                      ) > -1
                                                    : _vm.data.form.extra[key]
                                                        .packages[packKey]
                                                        .checked,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.data.form.extra[key]
                                                          .packages[packKey]
                                                          .checked,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.data.form.extra[
                                                              key
                                                            ].packages[packKey],
                                                            "checked",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.data.form.extra[
                                                              key
                                                            ].packages[packKey],
                                                            "checked",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.data.form.extra[key]
                                                          .packages[packKey],
                                                        "checked",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "custom-control-label",
                                                  attrs: {
                                                    for:
                                                      "extra-checked-" +
                                                      key +
                                                      packKey,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(pack.text) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.isArray(
                                        _vm.data.form.extra[key].packages[
                                          packKey
                                        ].plan
                                      )
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-lg-6 col-md-6",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _vm._l(
                                                      _vm.data.form.extra[key]
                                                        .packages[packKey].plan,
                                                      function (
                                                        planArr,
                                                        planArrKey
                                                      ) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              key: planArrKey,
                                                              staticClass:
                                                                "col-lg-6 col-md-6 my-2",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group",
                                                                },
                                                                [
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              _vm
                                                                                .data
                                                                                .form
                                                                                .extra[
                                                                                key
                                                                              ]
                                                                                .packages[
                                                                                packKey
                                                                              ]
                                                                                .plan[
                                                                                planArrKey
                                                                              ]
                                                                                .selected,
                                                                            expression:
                                                                              "\n                                                                    data.form.extra[key].packages[packKey].plan[\n                                                                        planArrKey\n                                                                    ].selected\n                                                                ",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "form-control extra-plan-select",
                                                                      attrs: {
                                                                        readonly:
                                                                          _vm
                                                                            .data
                                                                            .form
                                                                            .extra[
                                                                            key
                                                                          ]
                                                                            .packages[
                                                                            packKey
                                                                          ]
                                                                            .checked
                                                                            ? false
                                                                            : true,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal =
                                                                              Array.prototype.filter
                                                                                .call(
                                                                                  $event
                                                                                    .target
                                                                                    .options,
                                                                                  function (
                                                                                    o
                                                                                  ) {
                                                                                    return o.selected
                                                                                  }
                                                                                )
                                                                                .map(
                                                                                  function (
                                                                                    o
                                                                                  ) {
                                                                                    var val =
                                                                                      "_value" in
                                                                                      o
                                                                                        ? o._value
                                                                                        : o.value
                                                                                    return val
                                                                                  }
                                                                                )
                                                                            _vm.$set(
                                                                              _vm
                                                                                .data
                                                                                .form
                                                                                .extra[
                                                                                key
                                                                              ]
                                                                                .packages[
                                                                                packKey
                                                                              ]
                                                                                .plan[
                                                                                planArrKey
                                                                              ],
                                                                              "selected",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._l(
                                                                        planArr.option,
                                                                        function (
                                                                          plan,
                                                                          planKey
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "option",
                                                                              {
                                                                                key: planKey,
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      planKey,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                        " +
                                                                                    _vm._s(
                                                                                      plan
                                                                                    ) +
                                                                                    "\n                                                                    "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        }
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]
                                        : [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-lg-6 col-md-6 my-2",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.data.form
                                                                .extra[key]
                                                                .packages[
                                                                packKey
                                                              ].plan.selected,
                                                            expression:
                                                              "data.form.extra[key].packages[packKey].plan.selected",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control extra-plan-select",
                                                        attrs: {
                                                          readonly: _vm.data
                                                            .form.extra[key]
                                                            .packages[packKey]
                                                            .checked
                                                            ? false
                                                            : true,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              _vm.data.form
                                                                .extra[key]
                                                                .packages[
                                                                packKey
                                                              ].plan,
                                                              "selected",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._l(
                                                          pack.plan.option,
                                                          function (
                                                            plan,
                                                            planKey
                                                          ) {
                                                            return [
                                                              _c(
                                                                "option",
                                                                {
                                                                  key: planKey,
                                                                  domProps: {
                                                                    value:
                                                                      planKey,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(plan)
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    }
                  ),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-12" }, [_c("hr")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }