<template>
    <div class="pdf-viewer">
        <div class="pdf-manage">
            <button v-if="get(data, 'page') != null" @click="handle.page('prev')" class="btn btn-danger float-left">
                ก่อนหน้า
            </button>
            <button @click="handle.show" class="btn btn-danger">
                {{
                    get(data, 'page') == null
                        ? 'แสดงทั้งหมด'
                        : 'แสดงหน้าต่อหน้า ' + get(data, 'page') + '/' + get(data, 'pageCount')
                }}
            </button>
            <button v-if="get(data, 'page') != null" @click="handle.page('next')" class="btn btn-danger float-right">
                ถัดไป
            </button>
        </div>
        <vue-pdf-embed
            ref="pdf-viewer"
            :page="data.page"
            :source="'/media/pdf/insurance/' + source"
            @rendered="handle.render"
        ></vue-pdf-embed>
    </div>
</template>

<script>
import { reactive } from '@vue/composition-api';

//* plugins
import { cloneDeep, get, set } from 'lodash';

//* components
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
    name: 'module-pdf',
    components: { VuePdfEmbed },
    props: {
        source: String,
        page: Number,
    },
    setup(props, Vue) {
        const data = reactive({
            pageDefault: cloneDeep(props.page),
            pageCount: 0,
            page: props.page,
        });

        const handle = {
            render: () => {
                set(data, 'pageCount', Vue.refs['pdf-viewer'].pageCount);
            },
            show: () => {
                if (get(data, 'page') == null) {
                    set(data, 'page', cloneDeep(data.pageDefault));
                } else {
                    set(data, 'page', null);
                }
            },
            page: (to) => {
                if (to == 'next') {
                    if (get(data, 'page') != get(data, 'pageCount')) {
                        data.page++;
                    }
                } else {
                    if (get(data, 'page') != 1) {
                        data.page--;
                    }
                }
            },
        };

        return { data, props, handle, get, set };
    },
};
</script>

<style lang="scss">
.pdf-viewer {
    .pdf-manage {
        position: fixed;
        bottom: 22px;
        left: 0px;
        z-index: 9;
        width: 100%;
        padding: 0 7%;
        text-align: center;

        button {
            background: #e93666;
        }
    }
}
</style>
