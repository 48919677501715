var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pdf-viewer" },
    [
      _c("div", { staticClass: "pdf-manage" }, [
        _vm.get(_vm.data, "page") != null
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger float-left",
                on: {
                  click: function ($event) {
                    return _vm.handle.page("prev")
                  },
                },
              },
              [_vm._v("\n            ก่อนหน้า\n        ")]
            )
          : _vm._e(),
        _c(
          "button",
          { staticClass: "btn btn-danger", on: { click: _vm.handle.show } },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.get(_vm.data, "page") == null
                    ? "แสดงทั้งหมด"
                    : "แสดงหน้าต่อหน้า " +
                        _vm.get(_vm.data, "page") +
                        "/" +
                        _vm.get(_vm.data, "pageCount")
                ) +
                "\n        "
            ),
          ]
        ),
        _vm.get(_vm.data, "page") != null
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger float-right",
                on: {
                  click: function ($event) {
                    return _vm.handle.page("next")
                  },
                },
              },
              [_vm._v("\n            ถัดไป\n        ")]
            )
          : _vm._e(),
      ]),
      _c("vue-pdf-embed", {
        ref: "pdf-viewer",
        attrs: {
          page: _vm.data.page,
          source: "/media/pdf/insurance/" + _vm.source,
        },
        on: { rendered: _vm.handle.render },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }